<template>
  <component :is="template" class="mt-4"></component>
</template>

<script>
import { pluginOptions } from "/vue.config"
import mainTemplate from "./BusinessPrivacy/main"
import b_32 from "./BusinessPrivacy/32.vue"

export default {
  components: {
    mainTemplate,
    b_32,
  },
  mounted() {
    scrollTo({
      top: 10,
      behavior: "smooth"
    })
  },
  computed: {
    width() {
      return this.$store.getters.width;
    },
    template() {
      return pluginOptions.businesses && pluginOptions.businesses[location.hostname] ? "b_" + pluginOptions.businesses[location.hostname].id : "mainTemplate";
    },
  }
}

</script>

<style scoped>
/* .pg-header{
  background-image: url('../assets/images/backgrounds/6.jpg');
} */

.accordion-content ul {
  list-style: upper-roman;
}
</style>